(function($) {
    $(document).ready(function() {
        $('.menu-btn').on('click', function(){
            $('.common-header').toggleClass('expanded');
        });

        $('.search-form .fa-search').on('click', function(){
            $(this).parent('form').submit();
        });

        var $elem = $("body:not(.nofixed) header.page-header");
        if($elem.length > 0) {
            $elem.data('edge', $elem.offset().top);
            $(window).scroll(function() {
                var windowScrollTop = $(window).scrollTop();
                if ($elem.data('edge') < windowScrollTop && (windowScrollTop < ($(document).height() - $elem.height()))) {
                    $elem.addClass('fixed-active');
                }
                else {
                    $elem.removeClass('fixed-active');
                }
            });
        }

        $('.main-navigation .item')
            .on('mouseenter', function(){
                $('.submenu', this).slideToggle(450);
            })
            .on('mouseleave', function(){
                $('.submenu', this).hide();
            });

        if($('.bjqs').is('ul')) {
            var slider = $('.bjqs').bxSlider({
                    mode: 'fade',
                    auto: true,
                    pager: false,
                    controls: false,
                    speed: 1000,
                    pause: 7000
            });

            $('.bjqs .prev').on('click', function(){
                slider.goToPrevSlide();
            });
            $('.bjqs .next').on('click', function(){
                slider.goToNextSlide();
            });

            $('.bjqs .thumb').each(function(){
                $(this).css('backgroundImage', 'url('+$('img', this).attr('src')+')');
            });
        }

        $('.magazine-single .show-layer').on('click', function(){
            $('#addlayer').bPopup({
                contentContainer:'.addlayer .wrapper .box',
                loadUrl: $(this).attr('href')
            });
            return false;
        });

		(function() {
			var timeline = $('.timeline li');
			var current = 0;
			var pause = false;
			var hover = function(index){
				timeline.removeClass('hover');
				if($(window).width() > 980) {
					current = index;
					timeline.eq(index).addClass('hover');
				}
			}
			if(timeline.length > 0) {
				timeline.on('mouseenter', function() {
					hover($(this).index());
					pause = true;
				});
				timeline.on('mouseout', function() {
					pause = false;
				});
				setInterval(function() {
					if (pause == false) {
						hover(current);
						current++;
						if(current == timeline.length) {
							current = 0;
						}
					}
				}, 3000);
			}
		})();
    });
})(jQuery);

function initMap() {
    var directionsDisplay = new google.maps.DirectionsRenderer(),
        myLatlng = new google.maps.LatLng(52.218995,20.966843),
        image = '/css/i/map_marker.png',
        mapOptions, marker, map;

    mapOptions = {
        // How zoomed in you want the map to start at (always required)
        zoom: 17,

        // The latitude and longitude to center the map (always required)
        center: myLatlng,
        scrollwheel: false,
        navigationControl: false,
        mapTypeControl: false,
        scaleControl: false,
        disableDefaultUI: true,
        zoomControl: false,
        styles: [{
            "featureType": "landscape",
            "elementType": "labels",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "transit",
            "elementType": "labels",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "poi",
            "elementType": "labels",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "water",
            "elementType": "labels",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "stylers": [{
                "hue": "#00aaff"
            }, {
                "saturation": -100
            }, {
                "gamma": 2.15
            }, {
                "lightness": 12
            }]
        }, {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [{
                "visibility": "on"
            }, {
                "lightness": 24
            }]
        }, {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [{
                "lightness": 57
            }]
        }]
    };

    map = new google.maps.Map(document.getElementById('map'), mapOptions);
    image = 'http://basemetal.projektyiluste.pl/wp-content/themes/iluste_base/img/marker.png';
    marker = new google.maps.Marker({
        position: myLatlng,
        title: "Marquard Media Polska",
        icon: image
    });

    marker.setMap(map);
    directionsDisplay.setMap(map);
}

function startMap() {
    google.maps.event.addDomListener(window, 'resize', initMap);
    initMap();
}


